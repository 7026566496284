$(document).ready(function(){
  $(document)
    .on("ajax:success", '#session_login_form', (event) => {
      const [data, status, xhr] = event.detail;
      $('#session_login_errors').html('')
      if (data.message === 'Email') {
        $('#session_user_login_form').removeClass('hidden')
        $('#session_login_form_div').addClass('hidden')
        $('#talent_email').val(data.email)
        $('#company_user_email').val(data.email)
      } else if (data.message === 'Phone') {
        $('#session_submit_code_form').removeClass('hidden')
        $('#session_login_form_div').addClass('hidden')
        $('#phone_number').val(data.phone)
        resendPhoneCode()
      } else {
        $('#session_login_errors').html(data.error)
      }
    })
    .on("ajax:error", (event) => {
      $('#session_login_errors').html('Error happened, you can try again later!')
    });

  $(document)
    .on("ajax:success", '#session_submit_code', (event) => {
      const [data, status, xhr] = event.detail;
      if (data.message === 'Wrong') {
        $('#session_login_errors').html(data.error)
      }
    })
    .on("ajax:error", (event) => {
      $('#session_login_errors').html('Error happened, you can try again later!')
    });
  resendPhoneCode()
});

const resendPhoneCode = () => {
  $('#resend-code').click(function () {
    $('#email_or_phone').val($('#email_or_phone').val())
    $('#login_submit').trigger('click');
  })
}
