$(document).ready(function () {
  $(document).on("click", 'input[type=radio][name="job[role_type]"]', () => {
    let role_type = $(
      'input[type=radio][name="job[role_type]"]:checked',
      "#new_job_form"
    ).val();
    if (role_type) {
      $.post({
        url: "/role_type_skills",
        data: { role_type },
        success: (response) => {
          $("#role_type_skills_select").html(response);
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  });

  $(document).on(
    "input",
    "#job_shift_preferences_weekdays_start_time",
    (evt) => {
      enforceHHMM(evt);
    }
  );
  $(document).on("input", "#job_shift_preferences_weekdays_end_time", (evt) => {
    enforceHHMM(evt);
  });
  $(document).on(
    "input",
    "#job_shift_preferences_weekends_start_time",
    (evt) => {
      enforceHHMM(evt);
    }
  );
  $(document).on(
    "input",
    "#job_shift_preferences_weekends_start_time",
    (evt) => {
      enforceHHMM(evt);
    }
  );

  $("#job_shift_preferences_weekdays_start_time").attr("maxlength", 5);
  $("#job_shift_preferences_weekdays_end_time").attr("maxlength", 5);
  $("#job_shift_preferences_weekends_start_time").attr("maxlength", 5);
  $("#job_shift_preferences_weekends_end_time").attr("maxlength", 5);

  $("#job_shift_preferences_weekdays_start_time").attr(
    "pattern",
    "^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$"
  );
  $("#job_shift_preferences_weekdays_end_time").attr(
    "pattern",
    "^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$"
  );
  $("#job_shift_preferences_weekends_start_time").attr(
    "pattern",
    "^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$"
  );
  $("#job_shift_preferences_weekends_end_time").attr(
    "pattern",
    "^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$"
  );

  function enforceHHMM(evt) {
    var currentInput = evt.currentTarget.value;
    var fixedInput = currentInput.replace(
      /[A-Za-z!@#$%^&*();'",.<>?\[\]{}~`_+=]/g,
      ""
    );

    if (Number(fixedInput[0]) === 2 && Number(fixedInput[1]) > 3) {
      fixedInput = fixedInput[0] + 3 + fixedInput.slice(2);
    }

    if (Number(fixedInput[3]) > 5) {
      fixedInput = fixedInput.slice(0, 3) + 5 + fixedInput.slice(4);
    }

    if (fixedInput[1] === ":") {
      fixedInput = "0" + fixedInput;
    }
    if (Number(fixedInput[0]) > 2 && fixedInput.length > 1) {
      fixedInput = "2" + fixedInput.slice(1);
    }

    if (fixedInput[2] !== ":") {
      fixedInput = fixedInput.slice(0, 2);
    }

    if (fixedInput.length > 5) {
      fixedInput = fixedInput.slice(0, 5);
    }
    evt.currentTarget.value = fixedInput;
  }

  $(document).on("change", "input[id^=job_employment_type_]", function () {
    const emp_type = $("input[id^=job_employment_type_]:checked").val();
    if (emp_type === "direct_hire") {
      toggleRateTypes();
      const rate_type = $("input[id^=job_rate_type_]:checked");
      if (["flat_rate", "piece_rate"].includes(rate_type.val())) {
        $("input[id^=job_rate_type_]").prop("checked", false);
      }
    } else {
      toggleRateTypes("enable");
    }
  });

  if (!$("#shift_preferences_weekdays").is(":checked")) {
    hideWeekdays();
  }

  if (!$("#shift_preferences_weekends").is(":checked")) {
    hideWeekends();
  }

  $(document).on("change", "#shift_preferences_weekdays", function () {
    if ($(this).is(":checked")) {
      showWeekdays();
    } else {
      hideWeekdays();
    }
  });

  $(document).on("change", "#talent_profile_hour_rate", function () {
    $("#matching_rate").html(`$${$(this).val()}/hr and Up`);
  });

  $(document).on("input", "#talent_profile_hour_rate", function () {
    $("#matching_rate").html(`$${$(this).val()}/hr and Up`);
  });

  $(document).on("change", "#talent_profile_per_diem_rate", function () {
    $("#per_diem_rate").html(`$${$(this).val()}/day and Up`);
  });

  $(document).on("input", "#talent_profile_per_diem_rate", function () {
    $("#per_diem_rate").html(`$${$(this).val()}/day and Up`);
  });

  $(document).on("change", "#talent_profile_salary_rate", function () {
    $("#salary_rate").html(`$${$(this).val()} and Up`);
  });

  $(document).on("input", "#talent_profile_salary_rate", function () {
    $("#salary_rate").html(`$${$(this).val()} and Up`);
  });

  $(document).on("change", ".certificate-type", function () {
    const field = $(this).closest(".nested-fields").find(".other-type-field");
    if ($(this).val() == "other") {
      field.removeClass("hidden");
    } else {
      field.children().first().val("");
      field.addClass("hidden");
    }
  });

  $(document).on("change", "#shift_preferences_weekends", function () {
    if ($(this).is(":checked")) {
      showWeekends();
    } else {
      hideWeekends();
    }
  });

  function showWeekdays() {
    $(".weekdays_start_and_end_times").show();
    $("#job_shift_preferences_weekdays_start_time").attr(
      "required",
      "required"
    );
    $("#job_shift_preferences_weekdays_end_time").attr("required", "required");
  }

  function hideWeekdays() {
    $(".weekdays_start_and_end_times").hide();
    $("#job_shift_preferences_weekdays_start_time").removeAttr("required");
    $("#job_shift_preferences_weekdays_end_time").removeAttr("required");
  }

  function showWeekends() {
    $(".weekends_start_and_end_times").show();
    $("#job_shift_preferences_weekends_start_time").attr(
      "required",
      "required"
    );
    $("#job_shift_preferences_weekends_end_time").attr("required", "required");
  }

  function hideWeekends() {
    $(".weekends_start_and_end_times").hide();
    $("#job_shift_preferences_weekends_start_time").removeAttr("required");
    $("#job_shift_preferences_weekends_end_time").removeAttr("required");
  }

  $(document).on("click", ".trigger_click", (e) => {
    e.preventDefault();
    const id = e.target.id;
    const parts = id.split("__");
    if (parts.length === 2) $(`#${parts[1]}`).trigger("click");
  });

  $(document).on("click", ".trigger_click_multiple", (e) => {
    e.preventDefault();
    const id = e.target.id;
    $(`.${id}`).trigger("click");
  });

  $(document).on("click", "input[id$=_form_submit]", function (e) {
    e.preventDefault();
    const button_id = e.target.id;
    const form_id = button_id.replace("_submit", "");
    validate_form(form_id);
  });

  $(document).on("change", "#talent_profile_has_no_resume", function () {
    if (document.getElementById("talent_profile_has_no_resume").checked) {
      let node = $("#employment_references");
      node = node || node[0];
      node.removeClass("hidden");
    } else {
      let node = $("#employment_references");
      node = node || node[0];
      node.addClass("hidden");
    }
  });
});

const humanize = (str, allCaps = false) => {
  if (str === null || str === "null" || str === undefined) return "";
  let frags = str.split(/[_\[]/);
  for (let i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].charAt(0).toUpperCase() + frags[i].slice(1).replace("]", "");
  }
  return allCaps ? frags.join(" ").toUpperCase() : frags.join(" ");
};

const disableButton = (el, disable = true) => {
  $(el).attr("disabled", disable);
  if (disable) {
    $(el).addClass("btn-disabled");
    $(el).removeClass("btn-primary");
  } else {
    $(el).addClass("btn-primary");
    $(el).removeClass("btn-disabled");
  }
};

const validate_form = (form_id) => {
  removeErrors();
  let errors = 0;
  // Upload files
  $(`#${form_id} input[type=file]:required`).each(function (e) {
    if ($(this).val() === "") {
      const accepts_empty_when_checked = [
        "talent_profile_resume_files",
        "talent_profile_has_no_skill_trade",
      ].includes($(this).attr("id"));
      const has_checked_checkbox =
        $("#talent_profile_has_no_resume").is(":checked") ||
        $("#talent_profile_has_no_skill_trade").is(":checked");
      if (
        !accepts_empty_when_checked ||
        (accepts_empty_when_checked && !has_checked_checkbox)
      ) {
        errors++;
        showFormFieldError(this, "file");
      }
    }
  });
  // Textfields
  $(`#${form_id} input[type=text]:required,[type=password]:required`).each(
    function (e) {
      field_value = $(this).val();
      if (field_value === "") {
        errors++;
        showFormFieldError(this, "text");
      } else if ($(this).data("pattern")) {
        const pattern = new RegExp($(this).data("pattern"));
        if (!field_value.match(pattern)) {
          errors++;
          showFormFieldError(this, "text");
        }
      } else if (
        $(this).attr("id") == "talent_profile_sin_number" &&
        !validateSIN(field_value)
      ) {
        errors++;
        showFormFieldError(this, "text");
      }
    }
  );
  // Radio buttons
  $(`#${form_id} input[type=radio]:required`).each(function () {
    if ($(this).attr("data-error")) {
      const field_name = $(this).attr("name");
      let radio_checked = false;
      if ($(`input[name="${field_name}"]`).is(":checked")) {
        radio_checked = true;
      }
      if (!radio_checked) {
        errors++;
        showFormFieldError(this, "radio");
      }
    }
  });
  // Select menus
  $(`#${form_id} select:required`).each(function () {
    if ($(this).val() === "") {
      errors++;
      showFormFieldError(this, "select");
    }
  });
  // Checkbox
  $(`#${form_id} input[type=checkbox]:required`).each(function () {
    if ($(this).attr("data-error")) {
      const field_name = $(this).attr("name");
      if ($(`input[name="${field_name}"]:checked`).length === 0) {
        errors++;
        showFormFieldError(this, "checkbox-accept");
      }
    }
  });
  if (errors === 0) {
    disableButton($(`#${form_id}_submit`));
    $(`#${form_id}`).submit();
  } else {
    $("#form_errors").html(
      `${errors} error(s) found. Please review the highlighted fields below!`
    );
    $("#form_errors").show();
    // $(".error_msg:first").attr("tabindex",-1).focus();
    $("html, body").animate(
      { scrollTop: $(".error_msg:first").offset().top - 100 },
      "slow"
    );
  }
};

const showFormFieldError = (el, type) => {
  if (!["radio", "checkbox"].includes(type)) {
    $(el).addClass("border-error");
  }
  const error_message = $(el).attr("data-error");
  if (error_message) {
    if (type == "radio") {
      $(el)
        .parent()
        .parent()
        .before(
          `<div class='error_msg caption1 text-red-600'>${error_message}</div>`
        );
    } else {
      $(el).before(
        `<div class='error_msg caption1 text-red-600'>${error_message}</div>`
      );
    }
  }
};

const removeErrors = () => {
  $(".announcement-success").remove();
  $(".error_msg").remove();
  $("#form_errors").hide();
  $(".border-error").each(function () {
    $(this).removeClass("border-error");
  });
};

function validateSIN(input = "") {
  let sin, check, even, tot;
  if (typeof input === "number") {
    input = input.toString();
  }
  sin = input.replace(/-|_| /g, "");

  if (sin.length === 9) {
    sin = sin.split("");
    check = +sin.pop();
    even = sin
      .filter(function (_, i) {
        return i % 2;
      })
      .map(function (n) {
        return n * 2;
      })
      .join("")
      .split("");

    tot = sin
      .filter(function (_, i) {
        return !(i % 2);
      })
      .concat(even)
      .map(function (n) {
        return +n;
      })
      .reduce(function (acc, cur) {
        return acc + cur;
      });

    return check === (10 - (tot % 10)) % 10;
  } else return false;
}

const toggleRateTypes = (action = "disable") => {
  if (action === "disable") {
    $("#job_rate_type_flat_rate")
      .attr("disabled", "disabled")
      .addClass("cursor-not-allowed")
      .next()
      .addClass("text-gray-400");
    $("#job_rate_type_piece_rate")
      .attr("disabled", "disabled")
      .addClass("cursor-not-allowed")
      .next()
      .addClass("text-gray-400");
  } else {
    $("#job_rate_type_flat_rate")
      .removeAttr("disabled")
      .removeClass("cursor-not-allowed")
      .next()
      .removeClass("text-gray-400");
    $("#job_rate_type_piece_rate")
      .removeAttr("disabled")
      .removeClass("cursor-not-allowed")
      .next()
      .removeClass("text-gray-400");
  }
};
