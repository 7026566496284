import Placeholder from "../images/sample-pdf-doc.png";

const isFileImage = (file) => file && file["type"].split("/")[0] === "image";

$(document).ready(function () {
  $(document).on("change", 'input[type="file"]', (e) => {
    const element_id = e.target.id;
    const element_name = e.target.name;
    const node = $(`#${element_id}`);
    const files = document.getElementById(element_id).files;
    if (files && files.length > 0) {
      const rand = Math.floor(Math.random() * 1000);
      $(node).attr({ required: false, id: `${element_id}_${rand}` });
      const newFile = $("<input />")
        .attr("type", "file")
        .attr("name", element_name)
        .attr("id", element_id)

        // .attr('data-error', node.attr('data-error'))
        .addClass("hidden");
      if (element_id.indexOf("profile_picture") < 0)
        newFile.attr("multiple", "multiple");
      // node.replaceWith(newFile);
      newFile.insertAfter(node);
      // const newFiles = $(node).clone(true).attr("id", `${element_id}_${rand}`).insertAfter(node);
      // console.log(node.id, element_id, element_name)
      const nodeToSend = node[0] || node;
      imagesPreview(nodeToSend, `show_${element_id}`);
    }
  });

  $(document).on("ajax:success", ".js-delete-file", (event) => {
    const [data, status, xhr] = event.detail;
    if (data.status === "ok") {
      location.reload();
    }
  });
});

const imagesPreview = (node = [], placeToInsertImagePreview) => {
  if (node.files && node.files.length > 0) {
    const isProfilePic =
      placeToInsertImagePreview.includes("profile_picture") ||
      placeToInsertImagePreview.includes("company_logo");
    const is_cert = placeToInsertImagePreview.includes("certificate_file");

    const classToHide = `.file_placeholder_${placeToInsertImagePreview.replace(
      "show_",
      ""
    )}`;

    if ($(classToHide).length > 0) {
      $(classToHide).first().hide();
    } else {
      $(".file_placeholder").first().hide();
    }
    const filesAmount = node.files.length;
    // $(`#${placeToInsertImagePreview}`).html('');
    for (let i = 0; i < filesAmount; i++) {
      if (isFileImage(node.files[i])) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const newImage = $($.parseHTML("<img>")).attr({
            src: e.target.result,
            width: "200",
            height: "200",
            class: `mb-4 mr-4 ${isProfilePic ? "avatar-xxl" : "w-64"}`,
          });
          // console.log(2, placeToInsertImagePreview)
          if (isProfilePic) $(`#${placeToInsertImagePreview}`).html(newImage);
          else if (is_cert) {
            const preview_id = node.dataset.preview_id;
            $(`#show_talent_profile_${preview_id}_certificate_file`)
              .parent()
              .find(".file-preview")
              .addClass("hidden");
            $(`#show_talent_profile_${preview_id}_certificate_file`).html(
              newImage
            );
          } else newImage.appendTo(`#${placeToInsertImagePreview}`);
        };
        reader.readAsDataURL(node.files[i]);
      } else {
        // $(`#${placeToInsertImagePreview}`).append(`Selected: ${files[i].name}`)
        if (is_cert) {
          const preview_id = node.dataset.preview_id;
          $(`#show_talent_profile_${preview_id}_certificate_file`).append(
            `<img src="${Placeholder}" class="mb-4 mr-4 w-64" />`
          );
        } else
          $(`#${placeToInsertImagePreview}`).append(
            `<img src="${Placeholder}" class="mb-4 mr-4 w-64" />`
          );
      }
    }
  }
};
