import consumer from "./consumer"

function subscribe_messaging (room_id) {
    if (window.matchMedia("(max-width: 768px)").matches) {
        window.scrollTo(0,document.body.scrollHeight);
    }
    var Messages = consumer.subscriptions.create(
      {
          channel: "MessagesChannel",
          chat_room_id: room_id
      },
      {
          connected: function() {
              console.log("connected to messages_channel "+ room_id);
              // Called when the subscription is ready for use on the server
          },

          disconnected: function() {
              console.log("disconnected from messages_channel");
              // Called when the subscription has been terminated by the server
          },

          received: function(data) {
              // Called when there's incoming data on the websocket for this channel
              $(".js--message-input").val("")
              $(".js--messages-beginning").hide();
              $(".js--message-history").append(data.message);
              if (window.matchMedia("(max-width: 768px)").matches) {
                  window.scrollTo(0,document.body.scrollHeight);
              }
              this.perform('mark_as_read', { id: data.message_id, receivable_type: $(".js--message-form").data("type") });
          }
      }
    );

    $(document).on("submit", ".js--message-form", function(e) {
        e.preventDefault();
        if ($(".js--message-input").val() != ""){
            const params = {
                chat_room_id:    $(".js--message-form").data("id"),
                company_id:      $(".js--message-form").data("company"),
                talent_id:       $(".js--message-form").data("talent"),
                authorable_type: $(".js--message-form").data("type"),
                message: {
                    "body":      $(".js--message-input").val()
                }
            };
            Messages.send(params);
        }
        return false;
    });
};

$(document).ready(function(){
    if ($(".js--message-form").length > 0) {
        subscribe_messaging($(".js--message-form").data("id"))
    }
    $(document).on("click", ".open_messages", function() {
        const room_id = $(this).attr('id');
        subscribe_messaging(room_id);
    })
});
